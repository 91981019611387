import styles from './HowItWorks.module.scss'
import { Trans, useTranslation } from 'next-i18next'
import parse from 'html-react-parser'
import classNames from 'classnames'
import { Button, Heading, List } from '@boltenergy-be/design-system'
import { HowItWorksProps } from 'features/referral/components/HowItWorks/types'
import { COMMUNITY_SALES_DEFAULT_REDUCTION } from 'features/referral/constants'
import { useState } from 'react'
import Drawer from 'components/drawer/Drawer'

const HowItWorks = ({ reward = COMMUNITY_SALES_DEFAULT_REDUCTION }: HowItWorksProps) => {
  // I18N
  const { t } = useTranslation(['referral'])

  // Local state
  const [showTerms, setShowTerms] = useState<boolean>(false)

  return (
    <section className="container section">
      <Heading as="h2" variant="h3">
        {t('referral.howItWorks.title')}
      </Heading>
      <ol className={styles.steps}>
        <li className={styles.step}>
          <span className={styles.stepNumber}>1</span>
          <div className={styles.stepBody}>
            <Heading as="h3" variant="h4">
              {t('referral.howItWorks.one.title')}
            </Heading>
            <p>{parse(t('referral.howItWorks.one.description'))}</p>
          </div>
        </li>

        <li className={classNames(styles.step, styles.centered)}>
          <span className={styles.stepNumber}>2</span>
          <div className={styles.stepBody}>
            <Heading as="h3" variant="h4">
              {t('referral.howItWorks.two.title')}
            </Heading>
            <p>{parse(t('referral.howItWorks.two.description'))}</p>
          </div>
        </li>

        <li className={styles.step}>
          <span className={styles.stepNumber}>3</span>
          <div className={styles.stepBody}>
            <Heading as="h3" variant="h4">
              {t('referral.howItWorks.three.title', { reward })}
            </Heading>
            <p>
              <Trans
                t={t}
                i18nKey="referral.howItWorks.three.description"
                values={{ reward }}
                components={{
                  CustomButton: <Button representation="link" onClick={() => setShowTerms(true)} />
                }}
              />
            </p>
          </div>
        </li>
      </ol>

      <Drawer isOpen={showTerms} onRequestClose={() => setShowTerms(false)} title={t('referral.howItWorks.drawer.title')}>
        <p className="mb-500">{t('referral.howItWorks.drawer.description', { reward })}</p>

        <List as="ul">
          {[1, 2, 3, 4].map((bullet) => (
            <li key={bullet}>{t(`referral.howItWorks.drawer.bullets.${bullet}`)}</li>
          ))}
        </List>
      </Drawer>
    </section>
  )
}

export default HowItWorks
